export const CHAINS = {
  HAM: {
    chainId: "0x13f8",
    chainName: "HAM Mainnet",
    rpcUrls: ["https://rpc.ham.fun"],
    nativeCurrency: { name: "Ethereum", symbol: "ETH", decimals: 18 },
    blockExplorerUrls: ["https://explorer.ham.fun"],
    contractAddress: "0x8A988Bd10df9E00D6aea2fEf62df5EB12c3B83F7",
    tokens: [
      { symbol: "HBT", address: "0x4bCA66F9282A2f7E6A3e66579a2a06183207FC4A" },
      { symbol: "Coming Soon", address: "0x0000000000000000000000000000000000000000" },
    ],
  },
  BASE: {
    chainId: "0x2105",
    chainName: "Base Mainnet",
    rpcUrls: ["https://base-rpc.publicnode.com"],
    nativeCurrency: { name: "Ethereum", symbol: "ETH", decimals: 18 },
    blockExplorerUrls: ["https://basescan.org"],
    contractAddress: "0x54C0c429113663aD9C8e442011c3EC58DE7A1A8a",
    tokens: [
      { symbol: "HBT", address: "0x8A988Bd10df9E00D6aea2fEf62df5EB12c3B83F7" },
      { symbol: "Coming soon", address: "0x0000000000000000000000000000000000000000" },
    ],
  },
};