import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import Navbar from './components/Navbar';
import Bridge from './components/Bridge';
import { CHAINS } from './configs/config';
import Info from './components/Info.jsx';

const App = () => {
  const [fromChain, setFromChain] = useState(null);
  const [toChain, setToChain] = useState(null);
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);

  const handleConnect = (web3, account) => {
    setProvider(web3);
    setAccount(account);
  };

  const handleSwitchNetwork = (chain) => {
    setFromChain(chain);
    if (chain === 'HAM') {
      setToChain('BASE');
    } else {
      setToChain('HAM');
    }
  };

  useEffect(() => {
    // Initialize the provider and account here if needed
  }, []);

  return (
    <div>
      <Navbar onConnect={handleConnect} onSwitchNetwork={handleSwitchNetwork} />
      {fromChain &&
        <Bridge
          provider={provider}
          account={account}
          fromChain={fromChain}
          setFromChain={setFromChain}
          toChain={toChain}
          setToChain={setToChain}
        />
      }
      <Info />
      
    </div>
  );
};

export default App;
