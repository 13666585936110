function Info() {
  return (
    <div className="flex flex-col items-center w-full max-w-md p-6 mx-auto mt-8 mb-5 text-gray-800 bg-white border-2 border-red-500 rounded-lg shadow-lg">
      <div className="w-full p-4 mb-1 bg-red-100 rounded-lg">
        <h3 className="mb-2 text-xl text-red-600">How to use bridge?</h3>
        <p className="mb-2">To use bridge you need to have it's native token HBT.
         You can get it on <a href="https://bbqswap.xyz/#/swap" target={"_blank"} className="text-blue-500">BBQ</a> (HAM chain) or <a href="https://app.uniswap.org/#/swap"  target={"_blank"} className="text-blue-500">Uniswap</a> (BASE chain).</p>
      
        <p className="mb-2">
          Once you have HBT on one of the chains, you can transfer it to the other one using bridge. Connect wallet, select chain you are bridging from and enter amount.
          Confirm transaction in your wallet. Within a minute relay will process it, no need to claim it on the other side. Tokens will be sent to your wallet directly.
        </p>
        <p className="mb-2">
          Please, keep in mind difference in prices across chains. There are possible delicious arbitrage opportunities, as well as some pitfalls that you should be aware of.
        </p>
        <p className="mb-2">
          In case if you have any questions or facing any issues, feel free to contact us on <a href="https://t.me/hambridge" className="text-blue-500" target={"_blank"}>Telegram</a>.
        </p>
        <p className="mb-2">
          <ul className="list-disc list-inside">
            <li>
              HBT on HAM chain address:{" "}
              <a className="text-blue-500" href="https://explorer.ham.fun/token/0x4bCA66F9282A2f7E6A3e66579a2a06183207FC4A" target={"_blank"}>
                0x4bCA66F9282A2f7E6A3e66579a2a06183207FC4A
              </a>
            </li>
            <li>
              HBT on BASE chain address:{" "}
              <a className="text-blue-500" href="https://basescan.org/token/0x8A988Bd10df9E00D6aea2fEf62df5EB12c3B83F7" target={"_blank"}>
                0x8A988Bd10df9E00D6aea2fEf62df5EB12c3B83F7
              </a>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
}
export default Info;
