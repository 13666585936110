import { ethers } from "ethers";
import React, { useState, useEffect, useCallback } from "react";
import Web3 from "web3";
import { CHAINS } from "../configs/config";
import BridgeABI from "../helpers/bridgeABI.js";
import ERC20ABI from "../helpers/erc20ABI.js";

const Bridge = ({ provider, account, fromChain, setFromChain, toChain, setToChain }) => {
  const [amount, setAmount] = useState("");
  const [selectedToken, setSelectedToken] = useState("HBT");
  const [balance, setBalance] = useState("");
  const [bridgeFee, setBridgeFee] = useState(0);
  const [calculatedAmount, setCalculatedAmount] = useState("");
  const [gasFee, setGasFee] = useState(0);

  setTimeout(async () => {
    await loadbalance();
  }, 6000);
  const executeBridging = async () => {
    try {
      const web3 = new Web3(provider);
      const token = CHAINS[fromChain].tokens.find((token) => token.symbol === selectedToken);
      if (!token) {
        console.error("Token not found.");
        return;
      }
      const bridgeContract = new web3.eth.Contract(BridgeABI, CHAINS[fromChain].contractAddress);
      const gas = await bridgeContract.methods.bridge(Web3.utils.toWei(amount, "ether")).estimateGas({ from: account });
      const gasPrice = await web3.eth.getGasPrice();
      const tx = await bridgeContract.methods.bridge(Web3.utils.toWei(amount, "ether")).send({ from: account, gas, gasPrice });
      console.log(tx);
    } catch (error) {
      console.error("Error approving and bridging: ", error);
    }
  };

  const loadbalance = async () => {
    if (provider && account) {
      if(fromChain){
        const token = CHAINS[fromChain].tokens.find((token) => token.symbol === selectedToken);
        if (token) {
          const web3 = new Web3(provider);
          const tokenContract = new web3.eth.Contract(ERC20ABI, token.address);
          console.log("Fetching balance...");
          const balance = await tokenContract.methods.balanceOf(account).call();
          setBalance(Web3.utils.fromWei(balance, "ether"));
        }
      }
    }
  };

  const loadBlockchainData = useCallback(async () => {
    if (provider && account) {
      if(fromChain){
      try {
        const web3 = new Web3(provider);
        await loadbalance();
        const bridgeContract = new web3.eth.Contract(BridgeABI, CHAINS[fromChain].contractAddress);
        console.log("Fetching bridge fee...");
        const fee = await bridgeContract.methods.bridgeFee().call();
        setBridgeFee(parseInt(fee) / 100);
        const additionalFee = Web3.utils.fromWei(await bridgeContract.methods.additionalFee().call(), "ether");
        setGasFee(additionalFee == "0." ? "0" : additionalFee);
      } catch (error) {
        console.error("Error loading blockchain data: ", error);
      }
      }
    }
  }, [provider, account, fromChain, selectedToken]);

  useEffect(() => {
    loadBlockchainData();
  }, [provider, account, fromChain, selectedToken, loadBlockchainData]);

  useEffect(() => {
    if (amount && bridgeFee) {
      const calculated = amount - amount * bridgeFee / 100;
      setCalculatedAmount(calculated);
    }
  }, [amount, bridgeFee]);

  return (
    <div className="flex flex-col items-center w-full max-w-md p-6 mx-auto mt-8 text-gray-800 bg-white border-2 border-red-500 rounded-lg shadow-lg">
      <div className="w-full p-4 mb-4 bg-red-100 rounded-lg">
        <h3 className="mb-2 text-xl text-red-600">Swap</h3>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <label className="text-sm text-red-600">From Chain</label>
            <select
              className="p-2 text-gray-800 bg-white border border-red-500 rounded"
              value={fromChain}
              onChange={(e) => {
                setFromChain(e.target.value);
                setToChain(e.target.value === "HAM" ? "BASE" : "HAM");
                setSelectedToken("HBT"); // Reset selected token when chain changes
              }}
            >
              <option value="HAM">Ham chain</option>
              <option value="BASE">Base chain</option>
            </select>
          </div>
          <div className="flex flex-col space-y-2">
            <label className="text-sm text-red-600">Token</label>
            <select
              className="p-2 text-gray-800 bg-white border border-red-500 rounded"
              value={selectedToken}
              onChange={(e) => setSelectedToken(e.target.value)}
            >
              {CHAINS[fromChain].tokens.map((token) => (
                <option key={token.address} value={token.symbol}>
                  {token.symbol}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col space-y-2">
            <label className="text-sm text-red-600">Amount</label>
            <input
              type="text"
              className="p-2 text-gray-800 bg-white border border-red-500 rounded"
              placeholder="0"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <small className="text-gray-600">
              Balance: {balance} {selectedToken}
            </small>
          </div>
          <div className="flex flex-col space-y-2">
            <label className="text-sm text-red-600">To Chain</label>
            <select
              className="p-2 text-gray-800 bg-white border border-red-500 rounded"
              value={toChain}
              onChange={(e) => setToChain(e.target.value)}
              disabled
            >
              <option value="BASE">Base</option>
              <option value="HAM">Ham</option>
            </select>
            <input
              type="text"
              className="p-2 text-gray-800 bg-white border border-red-500 rounded"
              placeholder="0"
              disabled
              value={calculatedAmount}
            />
            <small className="text-gray-600">
              Bridge Fee: {amount * (bridgeFee) / 100} {selectedToken} ({bridgeFee}%)
            </small>
            <small className="text-gray-600">Gas Fee: {gasFee} HBT</small>
          </div>
          <button onClick={executeBridging} className="w-full px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-600">
            Bridge Tokens
          </button>
        </div>
      </div>
    </div>
  );
};

export default Bridge;
