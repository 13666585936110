import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { CHAINS } from '../configs/config';

const Navbar = ({ onConnect, onSwitchNetwork }) => {
  const [account, setAccount] = useState(null);
  const [currentChain, setCurrentChain] = useState(null);

  const loadBlockchainData = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        onConnect(web3, accounts[0]);
        fetchCurrentChainId();
      } catch (error) {
        console.error("Error loading blockchain data: ", error);
      }
    } else {
      console.log('No Ethereum provider found. Please install MetaMask!');
    }
  };

  const fetchCurrentChainId = async () => {
    if (window.ethereum) {
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      const chainName = Object.keys(CHAINS).find(chain => CHAINS[chain].chainId === chainId);
      setCurrentChain(chainName || 'BASE');
      onSwitchNetwork(chainName || 'BASE');
    }
  };

  const switchNetwork = async (chain) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: CHAINS[chain].chainId }],
        });
        setCurrentChain(chain);
        onSwitchNetwork(chain);
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: CHAINS[chain].chainId,
                chainName: CHAINS[chain].chainName,
                rpcUrls: CHAINS[chain].rpcUrls,
                nativeCurrency: CHAINS[chain].nativeCurrency,
                blockExplorerUrls: CHAINS[chain].blockExplorerUrls,
              }],
            });
            setCurrentChain(chain);
            onSwitchNetwork(chain);
          } catch (addError) {
            console.error("Error adding chain: ", addError);
          }
        }
      }
    } else {
      console.log('MetaMask is not installed. Please install MetaMask to continue.');
    }
  };

  useEffect(() => {
    fetchCurrentChainId();
  }, []);

  return (
    <nav className="flex items-center justify-between w-full p-4 bg-red-500 shadow-lg">
      <img src="/images/logo_white.png" className="rounded-full" alt='Hambridge Logo' style={{ width: "220px", height: "50px" }} />
      <div className="flex items-center space-x-4">
        <a href="https://warpcast.com/~/channel/hambridge" target={"_blank"} className="text-white"><img height={"20px"} width={"20px"} src="https://play-lh.googleusercontent.com/cRcdfJ01plmO9AhusWRZ1uyrjcYbbMMiyqTakPEHatoNVEzxtFt-78GJ7IZX-1cd2Vz2" /></a>
        <a href="https://t.me/hambridge" target={"_blank"} className="text-white" ><img height={"20px"} width={"20px"} src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/768px-Telegram_logo.svg.png?20220101141644" /></a>
        <select
          className="p-2 text-gray-800 bg-red-200 border border-red-600 rounded"
          value={currentChain}
          onChange={(e) => switchNetwork(e.target.value)}
        >
          <option value="HAM">Ham chain</option>
          <option value="BASE">Base chain</option>
        </select>
        <button
          className="px-4 py-2 font-bold text-white bg-red-700 rounded hover:bg-red-800"
          onClick={loadBlockchainData}
        >
          {account ? `Connected: ${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Connect Wallet'}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
